<template>
  <v-card flat class="pa-0 mt-1">
    <v-card-text class="pa-0">
      <h3 :key="uniqueKey" v-if="projectEquipmentObj.isProjectEquipment" class="pa-1 pl-2">{{ $t('message.projects.project')
        }}:
        <router-link :to="`/projects/${projectEquipmentObj.projectId}`">{{ projectTitle }}</router-link>
      </h3>
    </v-card-text>
    <scroll-list v-if="renderScrollList" id="equipmentScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      count: 1,
      MODULE: '',
      projectEquipmentObj: {
        isProjectEquipment: false,
        projectId: 0
      },
      listOfStatus: [this.$t('message.equipments.registered'), this.$t('message.equipments.process'), this.$t('message.equipments.closed')],
      scrollListPayload: {
        width: 65,
        loading: false,
        module: '',
        moduleTitle: 'message.layout.equipments',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.equipments.equipmentNumber',
          'message.common.name',
          'message.equipments.placement'
        ],
        items: [{
          value: 'equipment_number',
          class: 'pa-1 text-justify',
          cols: '4',
          spanClass: 'pl-2',
          isParagraph: true
        }, {
          value: 'name',
          class: 'pa-1 text-justify',
          cols: '4',
          spanClass: 'pl-2',
          isParagraph: true
        },
        {
          value: 'located',
          cols: '4',
          class: 'pa-1 text-justify'
        }],
        hasDelete: false,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      renderScrollList: false,
      hasLoadedAllData: false,
      uniqueKey: false,
      projectTitle: ''
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    this.MODULE = 'equipment'
    if (this.$route.name === 'project_equipment') {
      this.MODULE = 'projectequipment'
      this.projectEquipmentObj.isProjectEquipment = true
      this.uniqueKey = new Date().valueOf()
      this.projectEquipmentObj.projectId = parseInt(this.$route.params.project_id)
    }
    this.scrollListPayload.editHandler = (item) => this.$router.push(this.projectEquipmentObj.isProjectEquipment ? `/equipments/${item.id}?project_id=${this.projectEquipmentObj.projectId}` : `/equipments/${item.id}`)
    this.scrollListPayload.addBtnTo = this.projectEquipmentObj.isProjectEquipment ? `/equipments/add?project_id=${this.projectEquipmentObj.projectId}` : '/equipments/add'
    if (this.$store.state.common.isEquipmentExcluded) this.$router.push('/')
    this.$store.dispatch('getGeneralSettings')
  },
  mounted () {
    this.getListHandler()
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (search) {
      if (this.hasLoadedAllData) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `${this.getHostRefApi}equipment?only_items=1&start=${model.start}&length=${model.length}&status=1`
      if (this.projectEquipmentObj.isProjectEquipment) url += `&project_id=${this.projectEquipmentObj.projectId}`
      if (!this.scrollListPayload.showScanCode && search) url += `&search=${search}`
      hostAppApi.get(url)
        .then((response) => {
          if (response.data) {
            let { data, recordsFiltered, projectFullName } = response.data
            this.scrollListPayload.total = recordsFiltered
            const transformData = {
              date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
            }
            data = this.$formatter.formatListModel(data, transformData)
            data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
            this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
            if (data.length) this.scrollListPayload.totalCount = recordsFiltered
            if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.hasLoadedAllData = true
            this.projectTitle = projectFullName
          }
        })
        .finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('loadList')
  }
}
</script>
